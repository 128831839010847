import React from "react";
import {BrowserRouter as Router,Routes,Route,Navigate,} from "react-router-dom";
import Dashboard from "./Screens/Dashboard/Dashboard";
import ProductDetails from "./Screens/ProductDetails/ProductDetails";
import Wishlist from "./Screens/Wishlist/Wishlist";
import Cart from "./Screens/Cart/Cart";
import ProductList from "./Screens/ProductList/ProductList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminLogin from "./Screens/AdminLogin/AdminLogin";
import AdminProducts from "./Screens/AdminProducts/AdminProducts";
import AdminItems from "./Screens/AdminItems/AdminItems";
import AdminCategory from "./Screens/AdminCategory/AdminCategory";
import AdminSubcategory from "./Screens/AdminSubcategory/AdminSubcategory";
import AdminSubSubCategory from "./Screens/AdminSubSubCategory/AdminSubSubCategory";
import ItemCategories from "./Screens/ItemCategories/ItemCategories";
import AdminDashboard from "./Screens/AdminDashbord/AdminDashboard";
import AdminTax from "./Screens/AdminTax/AdminTax";
import AdminPopular from "./Screens/AdminPopular/AdminPopular";
import AdminItemPriceband from "./Screens/AdminItemPriceband/AdminItemPriceband";
import ItemsList from "./Screens/ItemsList/ItemsList";
import OrderList from "./Screens/orderList/OrderList";
import SingleOrderList from "./Screens/SingleOrderList/SingleOrderList";
import CancelOrder from "./Screens/CancelOrder/CancelOrder";
import SingleProduct from "./Screens/SingleProduct/SingleProduct";
import Error from "./Screens/Error/Error";
import AdminOrder from "./Screens/AdminOrder/AdminOrder";
import SingleDeletedOrderList from "./Screens/SingleDeletedOrderList/SingleDeletedOrderList";
import CategoriesDetails from "./Screens/CategoriesDetails/CategoriesDetails";

// css
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.css';
import SellerDashboard from "./Screens/SellerDashboard/SellerDashboard";
import SellerLogin from "./Screens/SellerLogin/SellerLogin";
import SellerSingUp from "./Screens/SellerSingUp/SellerSingUp";
import SellerCustomer from "./Screens/SellerCustomer/SellerCustomer";
import UserSignUp from "./Screens/UserSignUp/UserSignUp";
import UserSignin from "./Screens/UserSignin/UserSignin";
import AdminImportSales from "./Screens/AdminImportSales/AdminImportSales";
import Settings from "./Screens/Settings/Settings";
import AdminSalesOrderList from "./Screens/AdminSalesOrderList/AdminSalesOrderList";
import SellerOrderList from "./Screens/SellerOrderList/SellerOrderList";
import ContactUs from "./Screens/ContactUs/ContactUs";
import Contact from "./Screens/Contact/Contact";
import ForgotPassword from "./Screens/ForgotPassword/ForgotPassword";
import ResetPassword from "./Screens/ResetPassword/ResetPassword";
import AllocatedCustomer from "./Screens/AllocatedCustomer/AllocatedCustomer";
import AdminCustomerAllocation from "./Screens/AdminCustomerAllocation/AdminCustomerAllocation";
import AdminCustomerAllocationView from "./Screens/AdminCustomerAllocationView/AdminCustomerAllocationView";
import AdminCustomerList from "./Screens/AdminCustomerList/AdminCustomerList";
import AdminOrderListView from "./Screens/AdminOrderListView/AdminOrderListView";
import SalesForgetPassword from "./Screens/SalesForgetPassword/SalesForgetPassword";
import SalesResetPassword from "./Screens/SalesResetPassword/SalesResetPassword";
import Termsconditions from "./Screens/Termsconditions/Termsconditions";
import ScrollToTop from "./commponets/ScrollToTop";
import AdminImage from "./Screens/AdminImage/AdminImage";
import AdminSalesOrderListView from "./Screens/AdminSalesOrderListView/AdminSalesOrderListView";
import Shopcheckout from "./Screens/Shopcheckout/Shopcheckout";
import PublicRoute from "./service/PublicRoute";
import PrivateRoute from "./service/PrivateRoute";
import SellerPubilcRoute from "./service/SellerPubilcRoute";
import SellerPrivateRoute from "./service/SellerPrivateRoute";
// import 'bootstrap/dist/js/bootstrap.js';
<meta name="viewport" content="width=device-width, initial-scale=1" />; 

function App() {


  return (
    <Router>
       <ScrollToTop /> {/* Add the ScrollToTop component */}
      <div>
        
        <Routes>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/userSignUp" element={<UserSignUp />} />
          <Route path="/userSignin" element={<UserSignin />} />
          <Route path="/product" element={<ProductDetails />} />
          <Route path="/categories/:nameID/:name" element={<CategoriesDetails />} />
          <Route path="/ItemsList" element={<ItemsList />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/shop-checkout" element={<Shopcheckout />} />
          <Route path="/productList/:productId/:categoryId?" element={<ProductList />}/>
          <Route path="/singleProduct/:productId/:categoryId" element={<SingleProduct />} />
          <Route path="/orderList" element={<OrderList />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/singleOrder/:orderId" element={<SingleOrderList />} />
          <Route path="/singleDeleteOrder/:orderId" element={<SingleDeletedOrderList />} />
          <Route path="/cancelOrder" element={<CancelOrder />} />
          <Route path="/contact-us" element={<ContactUs/>} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/reset-password" element={<ResetPassword/>} />
          <Route path="/terms-conditions" element={<Termsconditions/>} />

          {/*admin  */}
          <Route path="/AdminLogin" element={<PublicRoute element={<AdminLogin />} />} />
          <Route path="/adminDashboard" element={<PrivateRoute element={<AdminDashboard />} />} />
          <Route path="/products" element={<PrivateRoute element={<AdminProducts />} />} />
          <Route path="/Taxes" element={<PrivateRoute element={<AdminTax />} />}/>
          <Route path="/category" element={<PrivateRoute element={<AdminCategory />} />}/>
          <Route path="/subcategory" element={<PrivateRoute element={<AdminSubcategory />} />} />
          <Route path="/sub-subcategory" element={<PrivateRoute element={<AdminSubSubCategory />} />} />
          <Route path="/items" element={<PrivateRoute element={<AdminItems />} /> }/>
          <Route path="/Item-Categories" element={<PrivateRoute element={<ItemCategories />} />}/>
          <Route path="/Popular" element={<PrivateRoute element={<AdminPopular />} />} />
          <Route path="/Item-Priceband" element={<PrivateRoute element={<AdminItemPriceband />} />}/>
          <Route path="/AdminOrder" element={<PrivateRoute element={<AdminOrder />} />} />
          <Route path="/AdminImportSales" element={<PrivateRoute element={<AdminImportSales/>} />}/>
          <Route path="/AdminSalesOrderList" element={<PrivateRoute element={<AdminSalesOrderList/>} />} />
          <Route path="/contact-List" element={<PrivateRoute element={<Contact/>} />}/>
          <Route path="/commission-Allocation" element={<PrivateRoute element={<SellerCustomer/>} />} />
          <Route path="/customer-Allocation" element={<PrivateRoute element={<AdminCustomerAllocation/>} />} />
          <Route path="/commission-Allocation-View" element={<PrivateRoute element={<AdminCustomerAllocationView/>} />}/>
          <Route path="/customer-List" element={<PrivateRoute element={<AdminCustomerList/>} />} />
          <Route path="/image" element={<PrivateRoute  element={<AdminImage/>} />} />
          <Route path="/order-view/:orderNumber" element={<PrivateRoute element={<AdminOrderListView />} />}/>
          <Route path="/salesOrder-view/:orderNumber" element={<PrivateRoute element={<AdminSalesOrderListView />} />}/>
          
          {/* seller */}
          <Route path="/sellerLogin" element={<SellerPubilcRoute element={<SellerLogin />} />} />
          <Route path="/sellerSingUp" element={<SellerPrivateRoute element={<SellerSingUp/>} /> }/>
          <Route path="/sellerDashboard" element={<SellerPrivateRoute element={<SellerDashboard />} />}/>
          <Route path="/sellerOrderList"  element={<SellerPrivateRoute element={<SellerOrderList/>} />}/>
          <Route path="/allocated-customer" element={<SellerPrivateRoute element={<AllocatedCustomer/>} />}/>
          <Route path="/sales-forgot-password" element={<SellerPrivateRoute element={<SalesForgetPassword/>} />}/>
          <Route path="/sales-reset-password" element={<SellerPrivateRoute element={<SalesResetPassword/>} />}/>

          <Route path="/404" element={<Error />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
