import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { Link, useLocation, useParams } from "react-router-dom";
import { BsEye, BsHeart } from "react-icons/bs";
import useApi from "../../service/useApi";
import { toast } from "react-toastify";
import { useCart } from "../../context/CartContext";
import "../../Screens/ProductDetails/ProductDetails.css";
import { PricebandContext } from "../../context/PricebandContext";
import { getUser } from "../../service/constants";
import second from "../../assets/images/avatar/coming_soon.jpg";
import Pagination from "../../commponets/Pagination";
import Footer from "../Footer/Footer";
import LoginModal from "../../commponets/LoginModal";
import { fetchDataFromAPI } from "../../service/api";
import { el } from "date-fns/locale";
import Processing from "../../commponets/Processing/Processing";
function ProductDetails() {
  const { nameID, name } = useParams();
  const { state } = useLocation();
  const { apiAction } = useApi();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [allPricebandListToken, setAllPricebandListToken] = useState([]);
  const [getshowList, setShowList] = useState([]);
  const [getshowSubList, setShowSubList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState({ key: "", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const { addToCart, removeFromCart } = useCart();
  const { categoryID, subCategoryID, subSubCategoryID, suggestionsId } =
    useContext(PricebandContext);
  const { categoryName, subCategoryName, subSubCategoryName, suggestionsName } =
    useContext(PricebandContext);
  const user = JSON.parse(getUser());
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubSubCategoryId, setSelectedSubSubCategoryId] =
    useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [likedItems, setLikedItems] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const { setCategoryID, setSubCategoryID, setSubSubCategoryID } =
    useContext(PricebandContext);
  const { setCategoryName, setSubCategoryName, setSubSubCategoryName } =
    useContext(PricebandContext);
  const [selectedName, setSelectedName] = useState("");
  const [totalItem, setTotalItem] = useState();
  const [itemStatus, setItemStatus] = useState('Active');
  const [loading, setLoading] = useState(false);
  console.log(searchQuery,"searchQuery");
  
  useEffect(() => {
    showList();
    showSubList();
    if (allPricebandList.length > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [allPricebandList]);

  useEffect(() => {
    setShowSubList([]);
  }, [categoryName, subCategoryName, subSubCategoryName]);

  const showList = () => {
    fetchDataFromAPI(`/user/subcategory/list/${categoryID}`, "get", "", "")
      .then((res) => {
        setShowList(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };
  const showSubList = () => {
    fetchDataFromAPI(
      `/user/subsubcategory/list/${subCategoryID}`,
      "get",
      "",
      ""
    )
      .then((res) => {
        setShowSubList(res?.data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  const btnShowList = (category) => {
    setCurrentPage(1);
    setSubCategoryID(category?.id);
    setSubCategoryName(category.name);
    setSelectedName(category.name);
  };
  const btnShowSubList = (category) => {
    setSelectedName("");
    setCurrentPage(1);
    setSubSubCategoryID(category?.id);
    setSubSubCategoryName(category.name);
    setCategoryName(category.name);
    setCategoryID(category.id);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [categoryID, subCategoryID, subSubCategoryID]);

  useEffect(() => {
    fetchItemList();
    fetchItemList_token();
    // setCurrentPage(1)
  }, [
    currentPage,
    sorting,
    searchQuery,
    selectedCategoryId,
    selectedSubCategoryId,
    selectedSubSubCategoryId,
    categoryID,
    subCategoryID,
    subSubCategoryID,
    pageSize,
  ]);

  const fetchItemList = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/user/item/list?page=${currentPage}`;
      if (sorting.order && sorting.key) {
        apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
      }

      // if (!categoryID && state?.search) {
      //   apiUrl += `&itemSearch=${state?.search}`;
      // }

      if (state?.search) {
        if (state?.search) {
          const encodedSearchTerm = encodeURIComponent(state.search);
          apiUrl += `&itemSearch=${encodedSearchTerm}`;
        } else {
          if (searchQuery) {
            apiUrl += `&itemSearch=${searchQuery}`;
          }
        }
      }
      // if (state?.search) {
      //   apiUrl += `&itemSearch=${state?.search}`;
      // }

      if (selectedCategoryId) {
        apiUrl += `&category_id=${selectedCategoryId}`;
      }
      if (selectedSubCategoryId) {
        apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
      }
      if (selectedSubSubCategoryId) {
        apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
      }
      if (categoryID) {
        apiUrl += `&category_id=${categoryID}`;
      }
      if (subCategoryID) {
        apiUrl += `&subcategory_id=${subCategoryID}`;
      }
      if (subSubCategoryID) {
        apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
      }
      if (nameID) {
        apiUrl += `&category_id=${nameID}`;
      }

      if (pageSize) {
        apiUrl += `&perPage=${pageSize}`;
      }
      const response = await apiAction({
        url: apiUrl,
        method: "GET",
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandList(pricebandData);
        setTotalItem(response.data.total);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchItemList_token = async () => {
    setIsLoading(true);
    try {
      let apiUrl = `/user/customer/item/list?page=${currentPage}`;
      if (sorting.order && sorting.key) {
        apiUrl += `&sorting=${sorting.order}&sortingKey=${sorting.key}`;
      }

      // if (!categoryID && state?.search) {
      //   apiUrl += `&itemSearch=${state?.search}`;
      // }

      if (state?.search) {
        if (state?.search) {
          const encodedSearchTerm = encodeURIComponent(state.search);
          apiUrl += `&itemSearch=${encodedSearchTerm}`;
        } else {
          if (searchQuery) {
            apiUrl += `&itemSearch=${searchQuery}`;
          }
        }
      }
      // if (state?.search) {
      //   apiUrl += `&itemSearch=${state?.search}`;
      // }

      if (selectedCategoryId) {
        apiUrl += `&category_id=${selectedCategoryId}`;
      }
      if (selectedSubCategoryId) {
        apiUrl += `&subcategory_id=${selectedSubCategoryId}`;
      }
      if (selectedSubSubCategoryId) {
        apiUrl += `&sub_subcategory_id=${selectedSubSubCategoryId}`;
      }
      if (categoryID) {
        apiUrl += `&category_id=${categoryID}`;
      }
      if (subCategoryID) {
        apiUrl += `&subcategory_id=${subCategoryID}`;
      }
      if (subSubCategoryID) {
        apiUrl += `&sub_subcategory_id=${subSubCategoryID}`;
      }
      if (nameID) {
        apiUrl += `&category_id=${nameID}`;
      }

      if (pageSize) {
        apiUrl += `&perPage=${pageSize}`;
      }
      const response = await apiAction({
        url: apiUrl,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setTotalItem(response.data.total);
        setAllPricebandListToken(pricebandData);
        setTotalPages(response.data.last_page);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (e) => {
    const { value } = e.target;
    const [key, order] = value.split(":");
    setSorting({ key, order });
  };

  useEffect(() => {
    if (state?.search) {
      setSearchQuery(state?.search);
    }
  }, [state?.search]);

  const handleSearchChange = (e) => {    
    setSearchQuery(e.target.value);
  };


  const handleLike = async (itemId) => {
    const newStatus = itemStatus === 'like' ? 'delike' : 'like'; // Toggle status
    const requestBody = new FormData();
    requestBody.append('item_id', itemId);

    try {
      setLoading(true); // Assuming you have a loading state to manage

      const response = await fetchDataFromAPI(
        `/user/item/like`,
        'post',
        requestBody,
        user?.token
      );

      if (response.status) {
        toast.success(response?.message);
        setItemStatus(newStatus); // Update local state
        // fetchItemList_token(); // Refetch the list if needed
      } else {
        toast.error(response?.messages || 'Failed to change status, please try again.');
      }
    } catch (error) {
      console.error('Error changing status:', error);
      toast.error('Failed to change status, please try again.');
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };


  const ProductCard = ({ product }) => {
    const handleAddToCart = () => {
      if (user === null) {
        setModalShow(true);
        return;
      }
      const productWithQuantity = {
        ...product,
        quantity: product.quantity || 1,
      };
      addToCart(productWithQuantity);
      toast.success("Product added to cart");
    };

    return (
      <div className="col">
        <div className="card card-product cards2">
          <div className="card-body">
            <div className="text-center position-relative">
              <Link
                to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                  ? `/${product.item_category[0].category_id}`
                  : ""
                  }`}
                className="text-decoration-none text-inherit"
                key={product.id}
              >
                <picture>
                  <img
                    src={product?.itemImage[0] || second}
                    alt="Product Image"
                    loading="lazy"
                    className="product-image1"
                    style={{ height: "100px", width: "100px" }}
                  />
                </picture>
              </Link>
              <div className="card-product-action">
                <Link
                  to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                    ? `/${product.item_category[0].category_id}`
                    : ""
                    }`}
                  key={product?.id}
                  className="btn-action"
                >
                  <BsEye />
                </Link>

              </div>
            </div>
            <div className="text-small mb-1 mt-1">
              <small>{product?.item_code}</small>
            </div>
            <small className="fs-6">{product?.name}</small>
            <div>
              <small className="text-warning">
                {product.rating &&
                  [...Array(Math.floor(product?.rating))].map((_, index) => (
                    <i key={index} className="bi bi-star-fill"></i>
                  ))}
                {product?.rating && product?.rating % 1 !== 0 && (
                  <i className="bi bi-star-half"></i>
                )}
              </small>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div>
                <Link to={'/userSignin'}>
                  <button
                    className="btn btn-primary btn-sm align-content-center"
                  >
                    Sign in for price
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const ProductCardToken = ({ product }) => {
    const handleAddToCart = () => {
      addToCart(product);
      toast.success("Product added to cart");
    };
    return (
      <div className="col">
        <div className="card card-product cards2">
          <div className="card-body">
            <div className="text-center position-relative">
              <Link
                to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                  ? `/${product.item_category[0].category_id}`
                  : ""
                  }`}
                className="text-decoration-none text-inherit"
                key={product?.id}
              >
                <picture>
                  <img
                    src={product?.itemImage[0] || second}
                    alt="Product Image"
                    loading="lazy"
                    className="product-image1"
                    style={{ height: "100px", width: "100px" }}
                  />
                </picture>
              </Link>
              <div className="card-product-action">
                <Link
                  to={`/productList/${product?.id}${product?.item_category[0]?.category_id
                    ? `/${product.item_category[0].category_id}`
                    : ""
                    }`}
                  key={product?.id}
                  className="btn-action"
                >
                  <BsEye />
                </Link>
                <a
                  href="#"
                  className="btn-action"
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="Wishlist"
                  style={{
                    backgroundColor: product?.is_liked ? "#FF0000" : "#008000",
                  }}
                  onClick={() => handleLike(product?.id)}
                >
                  <BsHeart
                    style={{
                      color: product?.is_liked ? "#fff" : "#fff",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="text-small mb-1 mt-1">
              <small>{product?.item_code}</small>
            </div>
            <h2 className="fs-6">{product?.name}</h2>
            <div>
              <small className="text-warning">
                {product?.rating &&
                  [...Array(Math.floor(product?.rating))].map((_, index) => (
                    <i key={index} className="bi bi-star-fill"></i>
                  ))}
                {product?.rating && product?.rating % 1 !== 0 && (
                  <i className="bi bi-star-half"></i>
                )}
              </small>
              <span className="text-muted small">{product?.numReviews}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <span className="text-dark">
                  {product?.currency} {product?.price}
                </span>
              </div>
              {product.stock > 0 ? (
                <div>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleAddToCart}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    Add
                  </button>
                </div>
              ) : (
                <button
                  className="btn btn-ghost-dark bg-gray-100 btn-sm"
                  disabled
                >
                  Out of stock
                </button>
              )}
              {/* <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={handleAddToCart}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  Add
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
      <Header />
      <Navbar />
      <main>
        {isLoading && <Processing />}

        <div className="mt-8 mb-lg-14 mb-8">
          <div className="container-fluid min-vh-100 justify-content-center align-items-center">
            <div className="container">
              <div className="row gx-10">
                {/* <section className="col-lg-9 col-md-12"> */}
                <div className="row gx-4 gx-lg-5 justify-content-center">
                  <div className="col-lg-9">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#" onClick={() => window.history.back()}>
                            Back
                          </a>
                        </li>
                        {categoryName && (
                          <li className="breadcrumb-item">{categoryName}</li>
                        )}
                        {subCategoryName && (
                          <li className="breadcrumb-item">{subCategoryName}</li>
                        )}
                        {subSubCategoryName && (
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {subSubCategoryName}
                          </li>
                        )}
                        {!categoryName &&
                          !subCategoryName &&
                          !subSubCategoryName && (
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Items
                            </li>
                          )}
                      </ol>
                    </nav>
                    {!getshowSubList?.length > 0 && (
                      <>
                        {getshowList?.length > 0 ? <h5>Subcategory</h5> : null}
                        <div className="mt-3 row row-cols-md-3 row-cols-lg-4">
                          {getshowList?.map((category) => (
                            <div
                              className=""
                              role="button"
                              key={category.id}
                              onClick={() => btnShowList(category)}
                            >
                              <a className="text-reset col card card-body align-items-center justify-content-center mt-2 h-75 ">
                                <h6 className="card-title mb-0 ">
                                  {category?.name}
                                </h6>
                              </a>
                            </div>
                          ))}
                        </div>
                      </>
                    )}

                    {getshowSubList?.length > 0 ? (
                      <h5>Sub Sub-category</h5>
                    ) : null}
                    <div className="mt-3 row row-cols-md-3 row-cols-lg-4">
                      {getshowSubList?.map((category) => (
                        <>
                          <div
                            className=""
                            role="button"
                            key={category.id}
                            onClick={() => btnShowSubList(category)}
                          >
                            <a className="text-reset col card card-body align-items-center justify-content-center mt-2 h-75 ">
                              <h6 className="card-title mb-0 ">
                                {category?.name}
                              </h6>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                    <div className="card mb-4 bg-light border-0 mt-6">
                      <div className="card-body p-4 p-lg-9">
                        <h2 className="mb-0 fs-2">
                          {selectedName ||
                            subSubCategoryName ||
                            categoryName ||
                            subCategoryName ||
                            "Items"}
                        </h2>
                      </div>
                    </div>
                    <div className="d-lg-flex justify-content-between align-items-center mb-4">
                      <div>
                        <p className="mb-0">
                          <span className="text-dark">
                            {allPricebandList?.length} of {totalItem}{" "}
                          </span>
                          Products found
                        </p>
                      </div>
                      <div className="d-md-flex align-items-center">
                        <div className="me-2 flex-grow-1">
                          <select
                            className="form-select"
                            onChange={handleSortChange}
                          >
                            <option value="name:asc">
                              Sort by: Name (A-Z)
                            </option>
                            <option value="name:desc">
                              Sort by: Name (Z-A)
                            </option>
                            <option value="price:asc">
                              Sort by: Price (Low to High)
                            </option>
                            <option value="price:desc">
                              Sort by: Price (High to Low)
                            </option>
                          </select>
                        </div>
                        <div className="mt-1 me-2 flex-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                            // value={searchQuery}
                            onChange={handleSearchChange}
                          />
                        </div>
                      </div>
                    </div>

                    {isLoading ? (
                      <div className="text-center mt-5">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {allPricebandList.length === 0 &&
                          allPricebandListToken?.length === 0 ? (
                          <div className="text-center mt-5">
                            <h2>No Product</h2>
                          </div>
                        ) : (
                          <div className="row row-cols-2 row-cols-md-2 row-cols-lg-4 g-4">
                            {user?.token == null
                              ? allPricebandList?.map((product) => (
                                <div key={product?.id} className="col">
                                  <ProductCard product={product} />
                                </div>
                              ))
                              : allPricebandListToken?.map((product) => (
                                <div key={product?.id} className="col">
                                  <ProductCardToken product={product} />
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    )}

                    <div className=" d-lg-flex justify-content-between align-items-center mb-4">
                      <div className="col mt-1">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={setCurrentPage}
                        />
                      </div>
                      <div className="mt-1 me-2 ">
                        <select
                          id="pageSize"
                          className="form-select"
                          name="pageSize"
                          value={pageSize}
                          onChange={(e) => setPageSize(e.target.value)}
                        >
                          <option value="12">Show 12 per page</option>
                          <option value="20">Show 20 per page</option>
                          <option value="48">Show 48 per page</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ProductDetails;
