import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import axios from 'axios'; // Import Axios
import { fetchDataFromAPI } from '../service/api';
import { getUser } from '../service/constants';

function AdminCustomerEditModel(props) {
    const [loading, setLoading] = useState(false);
    const [CustomerId, setCustomerId] = useState();
    const adminLogin = JSON.parse(localStorage.getItem('adminLogin'));
    const user = JSON.parse(getUser());
    const [priceBand, setPriceBand] = useState([]);
    const [priceband_id, setPriceband_id] = useState("");

    useEffect(() => {
        fetchPriceband()
        setCustomerId(props?.itemData?.id)
    }, [])
    const handleUploadData = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            customer_id: CustomerId,
            priceband_id: priceband_id,
        };
        fetchDataFromAPI("/admin/customer/allocate/priceband", "post", requestBody, user?.token)
            .then((response) => {
                if (response.status == true) {
                    toast.success(response.message);
                    props.onHide();
                    props.onSuccess();
                } else {
                    if (response.data) {
                        toast.error(response.data);
                    } else {
                        toast.error(response.data.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    const fetchPriceband = (page) => {
        setLoading(true); // Show loader
        fetchDataFromAPI(
            `/admin/item/priceband/list`,
            "get",
            "",
            user?.token
        )
            .then((response) => {
                setLoading(false); // Hide loader
                if (response?.status === true) {
                    setPriceBand(response.data)
                } else {
                    // toast.error(response.message);
                }
            })
            .catch((error) => {
                setLoading(false); // Hide loader on error
                console.error("Error fetching priceband list:", error);
            });
    };
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Upload Data</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>

                            <div className="mb-3">
                                <label htmlFor="priceband_id" className="form-label">Priceband</label>
                                <select
                                    value={priceband_id}
                                    className="form-control"
                                    id="priceband_id"
                                    onChange={(e) => setPriceband_id(e?.target?.value)}
                                    required
                                >
                                    <option value="">select Priceband</option>
                                    {priceBand?.map((priceband) => (
                                        <option key={priceband?.id} value={priceband?.id}>{priceband.type}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminCustomerEditModel;
