import React, { useContext, useEffect, useState } from 'react';
import AdminProfile from '../AdminProfile/AdminProfile';
import AdminNavbar from '../AdminNavbar/AdminNavbar';
import useApi from '../../service/useApi';
import { toast } from 'react-toastify';
import AdminProductModal from '../../commponets/AdminProductModal'; // Corrected typo in import
import { fetchDataFromAPI } from '../../service/api';
import { PricebandContext } from '../../context/PricebandContext';
import { getUser } from '../../service/constants';
import Pagination from '../../commponets/Pagination';
import { BsPencilSquare } from "react-icons/bs";
// import './AdminProducts.css'; // Add any custom styles if needed
import Button from 'react-bootstrap/Button';
import AdminCustomerEditModel from '../../commponets/AdminCustomerEditModel';
function AdminCustomerList() {
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [filteredPricebandList, setFilteredPricebandList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // State for loader
  const { apiAction } = useApi();
  const user = JSON.parse(getUser());
  const { setPricebandData } = useContext(PricebandContext);
  const [totalItems, setTotalItems] = useState(null);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [editItemData, setEditItemData] = useState(null);
  useEffect(() => {
    fetchPricebandList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    filterPricebandList();
  }, [searchTerm, allPricebandList]);

  const fetchPricebandList = (page) => {
    setLoading(true); // Show loader
    fetchDataFromAPI(
      `/admin/customer/listing?perPage=10&page=${page}&itemSearch=${searchTerm}`,
      "get",
      "",
      user?.token
    )
      .then((response) => {
        setLoading(false);
        if (response.status === true) {
          const pricebandData = response.data.data;
          console.log(pricebandData, "--------price");
          setAllPricebandList(pricebandData);
          setPricebandData(pricebandData);
          setFilteredPricebandList(pricebandData);
          setTotalItems(response?.data?.total)
          setTotalPages(response?.data?.last_page || 1);
          console.log("priceband data: ", response?.data?.total);
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loader on error
        console.error("Error fetching priceband list:", error);
      });
  };

  const filterPricebandList = () => {
    if (searchTerm.trim() === '') {
      setFilteredPricebandList(allPricebandList);
    } else {
      const filteredList = allPricebandList.filter(item =>
        item?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) || item?.email?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setFilteredPricebandList(filteredList);
    }
  };


  const handleModalSuccess = () => {
    fetchPricebandList(currentPage);
  };

  const handleEditItemClick = (item) => {
    setEditItemData(item);
    setEditItemId(item?.id);
    setEditModalShow(true);
  };


  return (
    <>
      <AdminProfile />
      <div className="main-wrapper">
        <AdminNavbar />
        <AdminProductModal show={modalShow} onHide={() => setModalShow(false)} onSuccess={handleModalSuccess} />
        <AdminCustomerEditModel show={editmodalShow} onHide={() => setEditModalShow(false)} itemId={editItemId} onSuccess={handleModalSuccess} itemData={editItemData} />
        <main className="main-content-wrapper">
          <div className="container">
            <div className="row mb-8">
              <div className="col-md-12">
                <div className="d-md-flex justify-content-between align-items-center">
                  <div>
                    <h2>Customer List</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-12 mb-5">
                <div className="card h-100 card-lg">
                  <div className="px-6 py-6">
                    <div className="row justify-content-between">
                      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
                      <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchPricebandList();
                          }}
                        >
                          <div className="input-group">
                            <input
                              className="form-control"
                              placeholder="Search for products"
                              aria-label="Search"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevents the default form submission
                                  fetchPricebandList();
                                }
                              }}
                            />
                            <Button onClick={() => fetchPricebandList()}>
                              <span>Search</span>
                            </Button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    {loading ? (
                      <div className="text-center py-4">
                        <div className="loader-container" id="loader-container">
                          <div className="loader"></div>
                        </div>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
                          <thead className="bg-light">
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Phone2</th>
                              <th>Address</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Country</th>
                              <th>Priceband</th>
                              <th>Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredPricebandList.length > 0 ? (
                              filteredPricebandList?.map((item) => (
                                <tr key={item.id}>
                                  <td>
                                    {item?.name}
                                  </td>
                                  <td>
                                    {item?.email}
                                  </td>
                                  <td>
                                    {item?.phone}
                                  </td>
                                  <td>
                                    {item?.phone2}
                                  </td>
                                  <td>
                                    {item?.address}
                                  </td>
                                  <td>
                                    {item?.city}
                                  </td>
                                  <td>
                                    {item?.state}
                                  </td>
                                  <td>
                                    {item?.country}
                                  </td>
                                  <td>
                                    {item?.priceband?.type}
                                  </td>
                                  <td>
                                          <button
                                            className="btn-link dropdown-item"
                                            onClick={() => handleEditItemClick(item)}
                                          >
                                            <BsPencilSquare />

                                          </button>
                                        </td>
                                  {/* <td>{format(new Date(item.created_at), "dd MMMM yyyy hh:mm:ss a")}</td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  No Customer List found
                                </td>
                              </tr>
                            )
                            }
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <div className="border-top d-md-flex justify-content-between align-items-center px-6 py-6">
                    <span>Showing {currentPage} to {allPricebandList?.length} of {totalItems} entries</span>
                 
                 <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={setCurrentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default AdminCustomerList;
