import React, { useState } from 'react';
import register from '../../assets/images/banner/size332x332.png';
import { Link, useNavigate } from 'react-router-dom';
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { saveUserLocally } from '../../service/constants';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import Processing from '../../commponets/Processing/Processing';
import { BsHeart, BsEye, BsEyeSlash } from "react-icons/bs";
function UserSignUp() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [address, setAddress] = useState('');
    const [password, setPassword] = useState('');
    const [pincode, setPincode] = useState('');
    const [errors, setErrors] = useState({});
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false); 
    const selectCountry = (val) => {
        setCountry(val);
        setRegion('');
    };

    const selectRegion = (val) => {
        setRegion(val);
    };
    const handleSignUp = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when sign-in process starts

        const body = {
            name: name,
            email: email,
            phone: phone,
            phone2: phone2,
            address: address,
            pincode: pincode,
            password: password,
            city: city,
            state: region,
            country: country,
        };

        fetchDataFromAPI("/user/register", "post", body, "")
            .then((res) => {
                const userLogin = res?.data;
                if (res.status === true && userLogin) {
                    saveUserLocally(JSON.stringify(res?.data));
                    toast.success(res.message);
                    navigate("/");
                } else {
                    // Capture validation errors from the response and map to the form
                    const validationErrors = {};
                    if (res.errors) {
                        res.errors.forEach(error => {
                            if (error.includes("name")) {
                                validationErrors.name = error;
                            }
                            if (error.includes("email")) {
                                validationErrors.email = error;
                            }
                            if (error.includes("phone")) {
                                validationErrors.phone = error;
                            }
                            if (error.includes("address")) {
                                validationErrors.address = error;
                            }
                            if (error.includes("pincode")) {
                                validationErrors.pincode = error;
                            }
                            if (error.includes("password")) {
                                validationErrors.password = error;
                            }
                            if (error.includes("country")) {
                                validationErrors.country = error;
                            }
                            if (error.includes("state")) {
                                validationErrors.state = error;
                            }
                            if (error.includes("city")) {
                                validationErrors.city = error;
                            }
                        });
                        setErrors(validationErrors);
                    }
                    console.log(res, "--resres");

                    toast.error(res?.data);
                }
            })
            .catch((error) => {
                console.log("error", error);
            })
            .finally(() => {
                setLoading(false); // Set loading to false when sign-in process ends
            });
    };
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <section className="my-lg-14 my-8">
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0">
                        <img src={register} alt="Sign In" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
                        <div className="mb-lg-9 mb-5">
                            <h1 className="mb-1 h2 fw-bold">Start Shopping</h1>
                            <p>Welcome to Snackhouseuk! Enter your email to get started.</p>
                        </div>
                        <form className="needs-validation" noValidate="" onSubmit={handleSignUp}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                    id="name"
                                    placeholder="Name"
                                    required=""
                                />
                                {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email address</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="text"
                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                    id="email"
                                    placeholder="Email"
                                    required=""
                                />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone" className="form-label">Enter phone</label>
                                <input
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    type="text"
                                    className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                    id="phone"
                                    placeholder="Phone"
                                    required=""
                                />
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone2" className="form-label">Enter phone2</label>
                                <input
                                    value={phone2}
                                    onChange={(e) => setPhone2(e.target.value)}
                                    type="text"
                                    className={`form-control`}
                                    id="phone"
                                    placeholder="Phone"
                                    required=""
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="address" className="form-label">Address</label>
                                <input
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    type="text"
                                    className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                                    id="address"
                                    placeholder="Address"
                                    required=""
                                />
                                {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="pincode" className="form-label">Postcode</label>
                                <input
                                    value={pincode}
                                    onChange={(e) => setPincode(e.target.value)}
                                    type="pincode"
                                    className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                    id="pincode"
                                    placeholder="Postcode"
                                    required=""
                                />
                                {errors.pincode && <div className="invalid-feedback">{errors.pincode}</div>}
                            </div>


                            <div className="col-12">
                                <CountryDropdown
                                    value={country}
                                    onChange={(val) => selectCountry(val)}
                                    style={{ width: '100%', padding: '8px', borderColor: 'grey' }}
                                    className="form-label"
                                />
                            </div>
                            {country && (
                                <div style={{ marginBottom: '20px' }}>
                                    <label>State</label>
                                    <RegionDropdown
                                        country={country}
                                        value={region}
                                        onChange={(val) => selectRegion(val)}
                                        style={{ width: '100%', padding: '8px', }}
                                    />
                                </div>
                            )}
                            <div className="col-12">
                                <label htmlFor="city" className="form-label visually-hidden">City</label>
                                <input
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    type="city"
                                    className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                    id="city"
                                    placeholder="city"
                                    required=""

                                />
                                {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="formSigninPassword" className="form-label">Password</label>
                                <div className="input-group">
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type={passwordVisible ? "text" : "password"}
                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                        id="formSigninPassword"
                                        placeholder="*****"
                                        minLength={8}
                                        required=""
                                    />
                                    <button
                                        type="button"
                                        className="input-group-text bg-transparent border-start-0"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i
                                            className={`bi ${passwordVisible ? "bi-eye-fill" : "bi-eye-slash-fill"}`}
                                            style={{ color: passwordVisible ? "green" : "red" }}
                                        />
                                        {passwordVisible ?
                                            <BsEye />
                                            :
                                            <BsEyeSlash />
                                        }

                                    </button>
                                </div>
                                {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                            </div>
                             {/* Terms and Conditions Checkbox */}
                             <div className="form-check mb-3">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="terms"
                                    checked={termsAccepted}
                                    onChange={(e) => setTermsAccepted(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="terms">
                                    I agree to the <Link to="/terms-conditions">Terms and Conditions</Link>
                                </label>
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary" disabled={loading || !termsAccepted}>
                                    {loading ? (
                                        <Processing type="ThreeDots" color="#fff" height={10} width={30} />
                                    ) : (
                                        'Sign Up'
                                    )}
                                </button>
                            </div>
                            <div className="mt-3 text-center">
                                Don't have an account? <Link to="/userSignin"><span> Sign In</span></Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UserSignUp;
