import React, { useState } from 'react'
import Header from '../Header/Header'
import Navbar from '../Navbar/Navbar'
import { getUser } from '../../service/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import second from "../../assets/images/avatar/coming_soon.jpg";
import success from "../../assets/images/about/check5.png";
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { useCart } from '../../context/CartContext';
import { Modal, Button } from 'react-bootstrap';

function Shopcheckout() {
    const { clearCart } = useCart();
    const user = JSON.parse(getUser());
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const navigate = useNavigate();
    const { cart, totalPrice } = location.state || { cart: [], totalPrice: 0 };
    const calculateSubtotal = (price, quantity) => {
        return price * quantity;
    };
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        navigate("/");
    };

    const placeYourOrder = async (e) => {
        e.preventDefault();
        const formdata = new FormData();
        cart.forEach((item, index) => {
            formdata.append(`item_code[${index}]`, item?.item_code ? item?.item_code : item?.item?.item_code);
            formdata.append(`quantity[${index}]`, item.quantity?.toString());
        });
        if (user.sellerId) {
            formdata.append(`sales_id`, user.sellerId);
        }

        try {
            const response = await fetchDataFromAPI("/user/customer/item/order", "post", formdata, user?.token);
            if (response.status === true) {
                toast.success(response.message);
                clearCart();
                handleCloseModal();
                setShowSuccessModal(true);  // Show the success modal
            } else {
                if (response.data && response.data.type) {
                    toast.error(response.data.type[0]);
                } else {
                    toast.error(response.data.message || "An error occurred");
                }
            }
        } catch (error) {
            console.log("error", error);
            if (error.response) {
                console.log("Response data:", error.response.data);
                console.log("Response status:", error.response.status);
                console.log("Response headers:", error.response.headers);
                if (error.response.status === 403) {
                    toast.error("You are not authorized to perform this action.");
                } else {
                    toast.error("An error occurred: " + error.response.data.message);
                }
            } else if (error.request) {
                console.log("Request data:", error.request);
                toast.error("No response received from the server.");
            } else {
                console.log("Error message:", error.message);
                toast.error("Error in setting up the request.");
            }
        }
    };

    const placeOrder = () => {
        handleShowModal();
    };

    return (
        <>
            <Header />
            <Navbar />
            <main>
                <div className="mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{'Checkout'}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="mb-lg-14 mb-8 mt-8">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div>
                                    <div className="mb-8">
                                        <h1 className="fw-bold mb-0">Checkout</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-xl-7 col-lg-6 col-md-12">
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item py-4">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <a className="fs-5 text-inherit collapsed h4" >
                                                    Add delivery address
                                                </a>
                                            </div>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                                                <div className="mt-5">
                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-12 col-md-6 col-12 mb-4">
                                                            <div className="card card-body p-6">
                                                                <div className="form-check mb-4">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" defaultChecked />
                                                                    <label className="form-check-label text-dark" htmlFor="homeRadio">Home</label>
                                                                </div>
                                                                <address>
                                                                    <strong>{user.name}</strong>
                                                                    <br />
                                                                    {user.address}
                                                                    <br />
                                                                    {user.city} {user.state} {user.country}
                                                                    <br />
                                                                    <abbr title="Phone">P: {user?.phone}</abbr>
                                                                </address>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 offset-xl-1 col-xl-4 col-lg-6">
                                    <div className="mt-4 mt-lg-0">
                                        <div className="card shadow-sm">
                                            <h5 className="px-6 py-4 bg-transparent mb-0">Order Details</h5>
                                            <ul className="list-group list-group-flush">
                                                {
                                                    cart.map((cart, index) => {
                                                        return (
                                                            <li key={index} className="list-group-item px-4 py-3">
                                                                <div className="row align-items-center">
                                                                    <div className="col-2 col-md-2">
                                                                        <img src={cart.itemImage?.[0] || cart?.item?.itemImage?.[0] || second} alt="Ecommerce" className="img-fluid" />
                                                                    </div>
                                                                    <div className="col-5 col-md-5">
                                                                        <h6 className="mb-0">{cart?.name || cart?.item?.name}</h6>
                                                                    </div>
                                                                    <div className="col-2 col-md-2 text-center text-muted">
                                                                        <span>{cart?.quantity}</span>
                                                                    </div>
                                                                    <div className="col-3 text-lg-end text-start text-md-end col-md-3">
                                                                        <span className="fw-bold">{cart?.currency || cart?.item?.currency}{calculateSubtotal(cart?.item?.price || cart?.price, cart?.quantity)}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                <li className="list-group-item px-4 py-3">
                                                    <div className="d-flex align-items-center justify-content-between fw-bold">
                                                        <div>Subtotal</div>
                                                        <div>£{totalPrice}</div>
                                                    </div>
                                                </li>
                                                <div className="mt-2 d-flex justify-content-end">
                                                    <a onClick={placeOrder} className="btn btn-primary ms-2">Place Order</a>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to place this order?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={placeYourOrder}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Order Placed Successfully</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center align-items-center">
                    <img src={success} className='w-30 align-content-center justify-content-center' alt="Success" />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseSuccessModal}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Shopcheckout;
