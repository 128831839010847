import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { getUser } from '../service/constants';
import { fetchDataFromAPI } from '../service/api';

function AdminEditTax({ show, onHide, itemData, onSuccess }) {
    const [taxRate, setTaxRate] = useState('');
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(getUser());
    useEffect(() => {
        if (itemData) {
            setTaxRate(itemData?.tax_rate || '');
        }
    }, [itemData]);

    const handleSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            tax_rate: taxRate,
            id: itemData?.id
        };
        fetchDataFromAPI("/admin/tax/create", "post", requestBody, user?.token)
            .then((response) => {
                console.log(response, "---response");
                if (response.status == true) {
                    toast.success(response?.message);
                    onHide();
                    if (onSuccess) {
                        onSuccess(response);
                    }
                } else {
                    if (response.data.type) {
                        toast.error(response.data.type[0]);
                    } else {
                        toast.error(response.data.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    return (
        <Modal show={show} onHide={onHide} size="sm" centered>
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold">Edit Taxes</h5>
                        <Button variant="close" onClick={onHide} />
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Tax rate</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="name"
                                    placeholder="Enter subcategory name"
                                    value={taxRate}
                                    onChange={(e) => setTaxRate(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="secondary"
                                    onClick={onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdminEditTax;
