// categoryReducer.js
import {
    FETCH_CATEGORIES_REQUEST,
    FETCH_CATEGORIES_SUCCESS,
    FETCH_CATEGORIES_FAILURE,
} from "./actionTypes";

const initialState = {
    loading: false,
    categories: [],
    error: null,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return { ...state, loading: true };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: [
                    ...state.categories,
                    ...action.payload.filter(
                        (newCat) => !state.categories.some((cat) => cat.id === newCat.id)
                    ),
                ],
            };
        case FETCH_CATEGORIES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default categoryReducer;
