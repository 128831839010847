import React from 'react'
import { Navigate } from 'react-router-dom';
import { getUser } from './constants';

const SellerPubilcRoute = ({element}) =>  {
    const user = getUser();  // Get the customer from localStorage
    return user ? <Navigate to="/sellerDashboard" replace /> : element;

}

export default SellerPubilcRoute
