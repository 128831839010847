import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/js/bootstrap.js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PricebandProvider } from './context/PricebandContext';
import { CartProvider } from './context/CartContext';
import { Provider } from 'react-redux';
import store from './redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
<meta name="viewport" content="width=device-width, initial-scale=1" />
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
root.render(

    <PricebandProvider>
    <CartProvider>
    <Provider store={store}>
    <App />
    </Provider>
    </CartProvider>
    </PricebandProvider>

);
reportWebVitals();
