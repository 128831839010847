import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import useApi from '../service/useApi';
import { getUser } from '../service/constants';
import { fetchDataFromAPI } from '../service/api';

function AdimnTax(props) {
    const [name ,setName] =useState('')
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(getUser());
    const handleUploadData = (e) => {
        setLoading(true);
        e.preventDefault();
        const requestBody = {
            tax_rate: name,
        };
        fetchDataFromAPI("/admin/tax/create", "post", requestBody, user?.token)
            .then((response) => {
                console.log(response, "---response");
                if (response.status == true) {
                    toast.success(response.message);
                    props.onHide();
                    props.onSuccess();
                } else {
                    if (response.data.type) {
                        toast.error(response.data.type[0]);
                    } else {
                        toast.error(response.data.message || "An error occurred");
                    }
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="modal-content p-1">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-3 fw-bold" id="userModalLabel">Add Taxes</h5>
                        <Modal.Header closeButton />
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" noValidate onSubmit={handleUploadData}>
                            <div className="mb-3">
                                <label htmlFor="SubCategoryname" className="form-label">Tax Rate</label>
                                <input
                                    value={name}
                                    type='number'
                                    className="form-control"
                                    id="SubCategoryname"
                                    placeholder="Tax Rate"
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <div className="invalid-feedback">Please enter item name.</div>
                            </div>
                           
                            <div className="d-flex mb-3">
                                <Button
                                    type="button"
                                    className="btn btn-secondary w-100"
                                    onClick={props.onHide}
                                    disabled={loading}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    style={{ marginLeft: 7 }}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default AdimnTax;
