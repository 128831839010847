import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import Navbar from '../Navbar/Navbar';
import { useCart } from '../../context/CartContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { fetchDataFromAPI } from '../../service/api';
import { toast } from 'react-toastify';
import { getUser } from '../../service/constants';
import second from '../../assets/images/avatar/coming_soon.jpg';
import Footer from '../Footer/Footer';
import Swal from 'sweetalert2';
const Cart = () => {
  const { cart, removeFromCart, clearCart, increaseQuantity, decreaseQuantity, updateQuantity } = useCart();
  const [totalPrice, setTotalPrice] = useState(0);
  const user = JSON.parse(getUser());
  const navigate = useNavigate();
  useEffect(() => {
    if (cart.length > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [cart]);

  const calculateSubtotal = (price, quantity) => {
    return price * quantity;
  };

  useEffect(() => {
    let total = 0;
    cart.forEach(product => {
      const price = product?.item?.price || product?.price || 0;
      const quantity = product?.quantity || product?.item?.quantity || 0;
      total += calculateSubtotal(price, quantity);
    });
    setTotalPrice(total);
  }, [cart]);

  const totalItems = cart?.reduce((acc, curr) => acc + curr.quantity, 0);

  const placeOrder = async (e) => {
    navigate("/shop-checkout", { state: { cart, totalPrice } });
  };

  const handleIncreaseQuantity = (productId, availableStock) => {
    const product = cart.find(item => item.id === productId);
    if (product.quantity >= availableStock) {
      toast.error("This product is out of stock");
    } else {
      increaseQuantity(productId);
    }
  };

  const handleUpdateQuantity = (productId, newQuantity, availableStock) => {
    if (newQuantity > availableStock) {
      toast.error("This product is out of stock");
      updateQuantity(productId, availableStock);
    } else {
      updateQuantity(productId, newQuantity);
    }
  };

  const handleClearCart = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, clear it!'
    }).then((result) => {
      if (result.isConfirmed) {
        clearCart(); // Call clearCart if confirmed
        Swal.fire(
          'Cleared!',
          'Your cart has been cleared.',
          'success'
        );
      }
    });
  };


  return (
    <>
      <Header />
      <Navbar />
      <section className="mb-lg-14 mb-8 mt-8">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
              <li className="breadcrumb-item active" aria-current="page">{'Shop Cart'}</li>
            </ol>
          </nav>
          <div className="row mt-6">
            <div className="col-12">
              <div className="card py-1 border-0 mb-8">
                <div>
                  <h1 className="fw-bold">Shop Cart</h1>
                  <p>There are {cart?.length} products in this cart.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <div className="py-3">
                <ul className="list-group list-group-flush">
                  {cart.map(product => {
                    const price = product?.item?.price || product?.price || 0;
                    const quantity = product?.quantity || product?.item?.quantity || 0;
                    const name = (product?.item?.name || product?.name)?.length > 50
                      ? `${(product?.item?.name || product?.name).slice(0, 50)}...`
                      : product?.item?.name || product?.name;
                    const availableStock = product?.item?.stock || product?.stock || 0;
                    const currentQuantity = product?.quantity || product?.item?.quantity || 0;
                    const isOutOfStock = currentQuantity >= availableStock;
                    const productId = product?.id || (product?.item?.id ? product.item.id : null);
                    const toPath = productId ? `/productList/${productId}` : '/'; // Provide a fallback path if necessary
                    return (
                      <>
                        <li className="list-group-item py-3 ps-0 border-top">
                          <div className="row align-items-center">
                            <div className="col-6 col-md-6 col-lg-7">
                              <div className="d-flex">
                                {product?.itemImage?.[0] ? <img src={product?.itemImage?.[0] || second} alt="Ecommerce" className="icon-shape icon-xxl" /> :
                                  <img src={product?.item?.itemImage?.[0] || second} alt="Ecommerce" className="icon-shape icon-xxl" />}
                                <div className="ms-3">
                                  <a className="text-inherit">
                                    <h6 className="mb-0">{name}</h6>
                                  </a>
                                  <span>
                                    {product?.item_code ?
                                      <small className="text-muted">{product?.item_code}</small>
                                      :
                                      <small className="text-muted">{product?.item?.item_code}</small>
                                    }
                                  </span>
                                  <div className="mt-2 small lh-1">
                                    <a
                                      onClick={() => removeFromCart(product?.id)}
                                      href="#"
                                      className="text-decoration-none text-inherit">
                                      <span className="me-1 align-text-bottom">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={14}
                                          height={14}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-trash-2 text-success"
                                        >
                                          <polyline points="3 6 5 6 21 6" />
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                          <line x1={10} y1={11} x2={10} y2={17} />
                                          <line x1={14} y1={11} x2={14} y2={17} />
                                        </svg>
                                      </span>
                                      <span className="text-muted">Remove</span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-6 col-md-3 align-content-center justify-content-center">
                              {/* <div className="input-group input-spinner"> */}
                              <input type="button" value="-" className="button-minus btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary" onClick={() => decreaseQuantity(product?.id)} />
                              <input
                                type="number"
                                step="1"
                                max="10"
                                name="quantity"
                                className="btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary"
                                value={product.quantity}
                                onChange={(e) => handleUpdateQuantity(product.id, parseInt(e.target.value, 10), availableStock)}
                                style={{ width: '38%', height: '80%' }}
                              />
                              <input
                                type="button"
                                value="+"
                                className="button-plus btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary"
                                onClick={() => handleIncreaseQuantity(product?.id, availableStock)}
                                disabled={isOutOfStock}
                              />
                              {/* </div> */}
                            </div>
                            <div className="col-2 text-lg-end text-start text-md-end col-md-2">
                              <span className="fw-bold">
                                {product?.currency || product?.item?.currency}
                                {calculateSubtotal(product?.item?.price || product?.price, product?.quantity).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        </li>
                      </>
                    )
                  })}
                </ul>
                <div className="d-flex justify-content-between mt-4">
                  <Link to="/" className="btn btn-primary">Continue Shopping</Link>
                  <button className="btn btn-danger" onClick={handleClearCart}>Clear Cart</button>
                </div>

              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-5">
              <div className="mb-5 card mt-6">
                <div className="card-body p-6">
                  <h2 className="h5 mb-4">Summary</h2>
                  <div className="card mb-2">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                          <div>Total Items</div>
                        </div>
                        <span>{totalItems}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="d-grid mb-1 mt-4">
                    <h2 className="h5 mb-4">total</h2>
                    <button
                      className="btn btn-dark btn-lg d-flex justify-content-center align-items-center">
                      {/* // In the JSX rendering: */}
                      <span className="fw-bold">£{totalPrice.toFixed(2)}</span>
                    </button>
                  </div>
                  {totalPrice && totalItems !== 0 ? (
                    <div className="d-grid mb-1 mt-4">
                      <button
                        className="btn btn-primary btn-lg d-flex justify-content-center align-items-center"
                        type="submit"
                        onClick={placeOrder}>
                        Go to Checkout
                        <span className="fw-bold"></span>
                      </button>
                    </div>
                  )
                    :
                    <div className="d-grid mb-1 mt-4">
                      <button
                        className="btn btn-outline-secondary btn-lg d-flex justify-content-center align-items-center"
                      >
                        Go to Checkout
                        <span className="fw-bold"></span>
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Cart;
