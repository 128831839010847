import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUser } from './constants';

const PrivateRoute = ({ element }) => {
  const user = getUser(); // Get the user from localStorage

  return user ? element : <Navigate to="/AdminLogin" replace />;
};

export default PrivateRoute;
