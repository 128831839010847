import React, { useContext, useEffect, useState } from "react";
import "../../../src/Screens/ProductList/ProductList.css";
import { BsHeart, BsEye } from "react-icons/bs";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import { BsStar } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useApi from "../../service/useApi";
import { toast } from "react-toastify";
import ReactImageMagnify from "react-image-magnify";
import { useCart } from "../../context/CartContext";
import LoginModal from "../../commponets/LoginModal";
import { getUser } from "../../service/constants";
import second from "../../assets/images/avatar/coming_soon.jpg";
import '../../Screens/ProductList/ProductList.css'
import Footer from "../Footer/Footer";
import { PricebandContext } from "../../context/PricebandContext";
function SingleProduct() {
  const { productId, categoryId, categoryName } = useParams();
  const [modalShow, setModalShow] = useState(false);
  const [allPricebandList, setAllPricebandList] = useState([]);
  const [allPricebandListToken, setAllPricebandListToken] = useState([]);
  const [allPricebandReleted, setAllPricebandReleted] = useState([]);
  const [allPricebandReletedToken, setAllPricebandReletedToken] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apiAction } = useApi();
  const [likedItems, setLikedItems] = useState({});
  const displayToken = allPricebandListToken?.currency && allPricebandListToken?.price;
  const { addToCart } = useCart();
  const [quantity, setQuantity] = useState(1);
  const user = JSON.parse(getUser());
  const handleMouseMove = (event) => {
    const zoomElement = event.currentTarget;
    const { left, top, width, height } = zoomElement.getBoundingClientRect();
    const x = ((event.pageX - left) / width) * 100;
    const y = ((event.pageY - top) / height) * 100;
    zoomElement.style.backgroundPosition = `${x}% ${y}%`;
  };
  useEffect(() => {
    if (user === null) {
      setModalShow(true);
    }
  }, [user]);

  useEffect(() => {
    fetchItemList()
    fetchItemList_Token();
    if (categoryId) {
      fetchRelatedItem_token();
      fetchRelatedItem()
    }
  }, [categoryId]);


  // token na hoi to
  const fetchItemList = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/item/view?id=${productId}`,
        method: "GET",
      });
      if (response.status === true) {
        const pricebandData = response?.data;
        setAllPricebandList(pricebandData);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  // token hoi to
  const fetchItemList_Token = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/customer/item/view?id=${productId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        const pricebandData = response?.data;
        setAllPricebandListToken(pricebandData);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRelatedItem = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/related/item?category_id=${categoryId}`,
        method: "GET",
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandReleted(pricebandData);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };

  // with token
  const fetchRelatedItem_token = async () => {
    setIsLoading(true);
    try {
      const response = await apiAction({
        url: `/user/customer/related/item?category_id=${categoryId}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      if (response.status === true) {
        const pricebandData = response?.data?.data;
        setAllPricebandReletedToken(pricebandData);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    } finally {
      setIsLoading(false);
    }
  };
  const smallImageSrc = allPricebandList?.itemImage;

  const handleLike = async (itemID) => {
    const requestBody = {
      item_id: itemID,
    };
    const requestOptions = {
      url: "/user/item/like",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user?.token}`,
      },
      body: JSON.stringify(requestBody),
    };
    try {
      const response = await apiAction(requestOptions);
      if (response?.status === true && response.data.is_liked == true) {
        setLikedItems((prevState) => ({
          ...prevState,
          [itemID]: !prevState[itemID],
        }));
        toast.success(response.message);
      } else {
        // toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "------error");
    }
  };


  const handleQuantityChange = (e) => {
    const inputValue = e.target.value;

    // Ensure input value is a valid number or empty
    if (inputValue === "" || /^\d+$/.test(inputValue)) {
      const newQuantity = parseInt(inputValue, 10);

      if (newQuantity < 1) {
        toast.error("Quantity cannot be less than 1");
        setQuantity(1);
      } else if (newQuantity > allPricebandList?.stock) {
        toast.error("Quantity exceeds stock available");
        setQuantity(allPricebandList?.stock);
      } else {
        setQuantity(newQuantity);
      }
    } else {
      // Revert to previous valid quantity if input is invalid
      e.target.value = quantity;
    }
  };

  const handleDecrease = () => {
    setQuantity((prev) => {
      const newQuantity = Math.max(1, prev - 1);
      if (newQuantity < 1) {
        toast.error("Quantity cannot be less than 1");
        return 1;
      }
      return newQuantity;
    });
  };

  const handleIncrease = () => {
    setQuantity((prev) => {
      const newQuantity = Math.min(allPricebandList?.stock, prev + 1);
      if (newQuantity > allPricebandList?.stock) {
        toast.error("Quantity exceeds stock available");
        return allPricebandList?.stock;
      }
      return newQuantity;
    });
  };

  const handleAddToCart = (item) => {
    if (allPricebandList?.stock <= 0) {
      toast.error("Product out of stock");
      return;
    }
    addToCart(item, quantity);
    toast.success("Product added to cart");
  };


  return (
    <>
      <Header />
      <Navbar />
      {/* <LoginModal show={modalShow} onHide={() => setModalShow(false)} /> */}
      <main>
        <section className="mt-8">
          <div className="container">
            <div className="row">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><a href="#" onClick={() => window.history.back()}>Back</a></li>
                  <li className="breadcrumb-item active" aria-current="page">{allPricebandList?.name}</li>
                </ol>
              </nav>
              <div className="col-md-5 col-xl-6">
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      alt: "Wristwatch by Ted Baker London",
                      isFluidWidth: true,
                      src: allPricebandList?.itemImage?.[0] || second,
                    },
                    largeImage: {
                      src: allPricebandList?.itemImage?.[0] || second,
                      width: 1200,
                      height: 1800,
                    },
                  }}
                />
              </div>
              <div className="col-md-7 col-xl-6">
                <div className="ps-lg-10 mt-6 mt-md-0">
                  <h1 className="mb-1">{allPricebandList?.name}</h1>
                  <div className="fs-4">

                    <span className="fw-bold text-dark">
                      {displayToken ? `${allPricebandListToken?.currency}${allPricebandListToken?.price}` :
                        <div>
                          <Link to={'/userSignin'}>
                            <button
                              className="btn btn-primary btn-sm"
                            >
                              Sign in for price
                            </button>
                          </Link>
                        </div>
                      }
                    </span>
                  </div>
                  {
                    user === null ?
                      null :
                      <>
                        <hr className="my-6" />
                        <div className="mb-5"></div>
                        <div className="col-6 col-md-3 align-content-center justify-content-center">
                          <input
                            type="button"
                            value="-"
                            className="button-minus btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary"
                            onClick={handleDecrease}
                          />
                          <input
                            type="number"
                            step="1"
                            name="quantity"
                            className="quantity-field btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary"
                            style={{ width: '38%', height: '80%' }}
                            max={Math.min(10, allPricebandList?.stock)}
                            value={quantity}
                            onChange={handleQuantityChange}
                          />
                          <input
                            type="button"
                            value="+"
                            className="button-plus btn btn-sm quantity-field form-control-sm form-input border-1 border-secondary"
                            onClick={handleIncrease}
                          />

                        </div>
                        <div className="mt-3 row justify-content-start g-2 align-items-center">
                          {
                            allPricebandList?.stock > 0 ?
                              <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => handleAddToCart(allPricebandList)}
                                >
                                  <i className="feather-icon icon-shopping-bag me-2"></i>
                                  Add to cart
                                </button>
                              </div>
                              :
                              <div className="col-xxl-4 col-lg-4 col-md-5 col-5 d-grid">
                                <button className="btn btn-grey btn-ghost-dark" disabled>
                                  Out of stock
                                </button>
                              </div>
                          }
                          <div className="col-md-4 col-4">
                            <div className="col-md-4 col-4">
                              <a
                                className="btn btn-light"
                                href="#"
                                data-bs-toggle="tooltip"
                                data-bs-html="true"
                                aria-label="Compare"
                                style={{
                                  backgroundColor: likedItems[allPricebandList?.id]
                                    ? "#FF0000"
                                    : "#dedad9",
                                }}
                                onClick={() => handleLike(allPricebandList?.id)}
                              >
                                <BsHeart
                                  style={{
                                    color: likedItems[allPricebandList?.id]
                                      ? "#fff"
                                      : "#000",
                                  }}
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </>

                  }
                  <hr className="my-6" />
                  <div>
                    <table className="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <td>Product Code:</td>
                          <td>{allPricebandList?.item_code}</td>
                        </tr>
                        <tr>
                          <td>Stock:</td>
                          <td>{allPricebandList?.stock}</td>
                        </tr>
                        <tr>
                          <td>Description:</td>
                          <td>
                            <small>
                              <span>
                                <small className="fs-6">
                                  {allPricebandList?.description}
                                </small>
                              </span>
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="my-lg-14 my-14">
          <div className="container">
          {!allPricebandReleted || allPricebandReleted.length === 0 ?
            null
            :
           (
            <div className="col-12">
              <h3 className="">Related Items</h3>
            </div>
          )
        }
            <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-2 mt-2">
              {!allPricebandReleted || allPricebandReleted.length === 0 ? (
                <div className="col-12 mb-6 d-flex justify-content-center align-items-center">
                  <h5 className="mb-0 text-center">No Product</h5>
                </div>
              ) : (
                allPricebandReleted && allPricebandReletedToken ? (
                  allPricebandReletedToken.map((product) => {
                    const handleAddToCartReleted = () => {
                      addToCart(product);
                      toast.success("Product added to cart");
                    };
                    return (
                      <>
                        <div className="col" key={product.id}>
                          <div className="card card-product cards">
                            <div className="card-body">
                              <div className="text-center position-relative">
                                <Link
                                  // to={`/singleProduct/${product.id}`}
                                  to={`/singleProduct/${product?.id}${product?.item_category?.[0]?.category_id ? `/${product?.item_category?.[0].category_id}` : ''}`}
                                  className="text-decoration-none text-inherit"
                                >
                                  <picture>
                                    <img
                                      src={product?.itemImage?.[0] || second}
                                      alt="Product Image"
                                      loading="lazy"
                                      className="product-image1"
                                    />
                                  </picture>
                                </Link>
                                <div className="card-product-action">
                                  <Link
                                    to={`/singleProduct/${product?.id}${product?.item_category?.[0]?.category_id ? `/${product?.item_category?.[0].category_id}` : ''}`}
                                    className="btn-action"
                                  >
                                    <BsEye />
                                  </Link>
                                  <a
                                    href="#!"
                                    className="btn-action"
                                    data-bs-toggle="tooltip"
                                    data-bs-html="true"
                                    title="Wishlist"
                                    style={{
                                      backgroundColor: likedItems[product?.id]
                                        ? "#FF0000"
                                        : "#dedad9",
                                    }}
                                    onClick={() => handleLike(product?.id)}
                                  >
                                    <BsHeart
                                      style={{
                                        color: likedItems[product?.id]
                                          ? "#fff"
                                          : "#000",
                                      }}
                                    />
                                  </a>
                                </div>
                              </div>
                              <div className="text-small mb-1">
                                <small>{product?.item_code}</small>
                              </div>
                              <h2 className="fs-6">
                                {product?.name}
                              </h2>
                              <div>
                                <small className="text-warning">
                                  {product.rating &&
                                    [...Array(Math.floor(product.rating))].map((_, index) => (
                                      <i key={index} className="bi bi-star-fill"></i>
                                    ))}
                                  {product.rating && product.rating % 1 !== 0 && (
                                    <i className="bi bi-star-half"></i>
                                  )}
                                </small>
                                <span className="text-muted small">{product.numReviews}</span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center mt-3">
                                <div>
                                  <span className="text-dark">
                                    {product.currency}{product.price}
                                  </span>
                                </div>
                              </div>
                              {
                                product.stock > 0 ? (
                                  <div>
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={handleAddToCartReleted}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-plus"
                                      >
                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                      </svg>
                                      Add
                                    </button>
                                  </div>
                                ) : (
                                  <button className="btn btn-ghost-dark bg-gray-100 btn-sm" disabled>
                                    Out of stock
                                  </button>
                                )
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                )
                  :
                  (
                    allPricebandReleted.map((product) => {
                      const handleAddToCartReleted = () => {
                        addToCart(product);
                        toast.success("Product added to cart");
                      };
                      return (
                        <>
                          <div className="col" key={product.id}>
                            <div className="card card-product cards">
                              <div className="card-body">
                                <div className="text-center position-relative">
                                  <Link
                                    // to={`/singleProduct/${product.id}`}
                                    to={`/singleProduct/${product?.id}${product?.item_category?.[0]?.category_id ? `/${product?.item_category?.[0].category_id}` : ''}`}
                                    className="text-decoration-none text-inherit"
                                  >
                                    <picture>
                                      <img
                                        src={product?.itemImage?.[0] || second}
                                        alt="Product Image"
                                        loading="lazy"
                                        className="product-image1"
                                      />
                                    </picture>
                                  </Link>
                                  <div className="card-product-action">
                                    <Link
                                      to={`/singleProduct/${product?.id}${product?.item_category?.[0]?.category_id ? `/${product?.item_category?.[0].category_id}` : ''}`}
                                      className="btn-action"
                                    >
                                      <BsEye />
                                    </Link>
                                    <a
                                      href="#!"
                                      className="btn-action"
                                      data-bs-toggle="tooltip"
                                      data-bs-html="true"
                                      title="Wishlist"
                                    >
                                      <BsHeart />
                                    </a>
                                  </div>
                                </div>
                                <div className="text-small mb-1">
                                  <small>{product?.item_code}</small>
                                </div>
                                <h2 className="fs-6">
                                  {product?.name}
                                </h2>
                                <div>
                                  <small className="text-warning">
                                    {product.rating &&
                                      [...Array(Math.floor(product.rating))].map((_, index) => (
                                        <i key={index} className="bi bi-star-fill"></i>
                                      ))}
                                    {product.rating && product.rating % 1 !== 0 && (
                                      <i className="bi bi-star-half"></i>
                                    )}
                                  </small>
                                  <span className="text-muted small">{product.numReviews}</span>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <div>
                                    <span className="text-dark">
                                      {product.currency}{product.price}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={handleAddToCartReleted}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-plus"
                                    >
                                      <line x1="12" y1="5" x2="12" y2="19"></line>
                                      <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  )
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default SingleProduct;