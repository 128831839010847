// store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk'; // Named import for thunk
import categoryReducer from './categoryReducer'; 

const rootReducer = combineReducers({
    categories: categoryReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
