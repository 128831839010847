// categoryActions.js
import { fetchDataFromAPI } from "../service/api";
import {
    fetchCategoriesRequest,
    fetchCategoriesSuccess,
    fetchCategoriesFailure,
} from "./actions";
import { toast } from "react-toastify";

export const fetchCategoryList = (pageNumber) => {
    return async (dispatch) => {
        dispatch(fetchCategoriesRequest());
        fetchDataFromAPI(
            `/user/category/list?page=${pageNumber}`,
            "get",
            "",
        )
            .then((response) => {
                if (response.status === true) {
                    const newCategories = response.data;
                    dispatch(fetchCategoriesSuccess(newCategories));
                } else {
                    toast.error(response?.message);
                    dispatch(fetchCategoriesFailure(response?.message));
                }
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
                dispatch(fetchCategoriesFailure(error.message));
                console.log(error, "---error");
            });
    };
};

